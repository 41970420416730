import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 250px;
  grid-template-columns: 1fr 1fr 1fr 300px;
  column-gap: 1rem;
  padding-bottom: 31px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
    row-gap: 12px;
  }
`;


